// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取解盘栏目信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/channel */
export async function streamChannelUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamChannelUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStreamChannelResp>('/stream/channel', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取解盘老师列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/channel/analysts */
export async function streamChannelAnalystsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamChannelAnalystsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListAnalystInfo>('/stream/channel/analysts', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取解盘详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/message */
export async function streamMessageUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamMessageUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStreamMessageItem>('/stream/message', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取解盘消息列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/messages */
export async function streamMessagesUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamMessagesUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStreamMessageItem>('/stream/messages', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发送评论 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/messages/comment */
export async function streamMessagesCommentUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamMessagesCommentUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/stream/messages/comment', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 评论列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/messages/commentPage */
export async function streamMessagesCommentPageUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamMessagesCommentPageUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStreamMessageCommentResp>('/stream/messages/commentPage', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改解盘点赞状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 PUT /stream/messages/like */
export async function streamMessagesLikeUsingPUT(
  body: API.UserUpdateStreamMessageLikeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/stream/messages/like', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 按内容主获取解盘消息列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stream/team/messages */
export async function streamTeamMessagesUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.streamTeamMessagesUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListTeamStreamMessageItem>('/stream/team/messages', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
