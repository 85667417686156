// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as addressController from './addressController';
import * as analystController from './analystController';
import * as appAuthController from './appAuthController';
import * as appConfigController from './appConfigController';
import * as appCourseController from './appCourseController';
import * as appUserController from './appUserController';
import * as articleController from './articleController';
import * as articleCourseController from './articleCourseController';
import * as authController from './authController';
import * as chatDialogController from './chatDialogController';
import * as chatRoomController from './chatRoomController';
import * as chatRoomQaController from './chatRoomQaController';
import * as chatStreamMsgController from './chatStreamMsgController';
import * as checkInController from './checkInController';
import * as convertController from './convertController';
import * as couponController from './couponController';
import * as couponPlanController from './couponPlanController';
import * as courseCatalogController from './courseCatalogController';
import * as courseController from './courseController';
import * as customerComplaintController from './customerComplaintController';
import * as customerStrategyController from './customerStrategyController';
import * as customerTrackController from './customerTrackController';
import * as delayCouponController from './delayCouponController';
import * as eSignController from './eSignController';
import * as giftCouponController from './giftCouponController';
import * as giftGoodController from './giftGoodController';
import * as giftIntroduceController from './giftIntroduceController';
import * as invoiceController from './invoiceController';
import * as kfController from './kfController';
import * as liveRoomController from './liveRoomController';
import * as newsController from './newsController';
import * as noticeSubController from './noticeSubController';
import * as orderController from './orderController';
import * as orderPointController from './orderPointController';
import * as pointActivityController from './pointActivityController';
import * as pubController from './pubController';
import * as pubTeamController from './pubTeamController';
import * as raCustomerController from './raCustomerController';
import * as recommendStockController from './recommendStockController';
import * as rewardController from './rewardController';
import * as shortVideoController from './shortVideoController';
import * as signController from './signController';
import * as skuController from './skuController';
import * as smsController from './smsController';
import * as softwarePackageController from './softwarePackageController';
import * as stockCaseController from './stockCaseController';
import * as stockCaseNewController from './stockCaseNewController';
import * as stockInfoController from './stockInfoController';
import * as strategyApplyController from './strategyApplyController';
import * as strategyBackTestController from './strategyBackTestController';
import * as strategyController from './strategyController';
import * as strategyCouponController from './strategyCouponController';
import * as strategySimulateController from './strategySimulateController';
import * as strategySimulationBackTestController from './strategySimulationBackTestController';
import * as strategyStockController from './strategyStockController';
import * as streamController from './streamController';
import * as surveyController from './surveyController';
import * as teamVideoController from './teamVideoController';
import * as userController from './userController';
import * as vipCouponController from './vipCouponController';
import * as vipNoticeController from './vipNoticeController';
import * as vodController from './vodController';
import * as webOssConfigController from './webOssConfigController';
import * as weimobController from './weimobController';
import * as workWxController from './workWxController';
import * as wxController from './wxController';
import * as wxMaController from './wxMaController';
import * as wxOauth2Controller from './wxOauth2Controller';
import * as wxTrackController from './wxTrackController';
export default {
  addressController,
  analystController,
  appAuthController,
  appConfigController,
  appCourseController,
  appUserController,
  articleController,
  articleCourseController,
  authController,
  chatDialogController,
  chatRoomController,
  chatRoomQaController,
  chatStreamMsgController,
  checkInController,
  convertController,
  couponController,
  couponPlanController,
  courseCatalogController,
  courseController,
  customerComplaintController,
  customerStrategyController,
  customerTrackController,
  delayCouponController,
  eSignController,
  giftCouponController,
  giftGoodController,
  giftIntroduceController,
  invoiceController,
  kfController,
  liveRoomController,
  newsController,
  noticeSubController,
  orderController,
  orderPointController,
  pointActivityController,
  pubController,
  pubTeamController,
  raCustomerController,
  recommendStockController,
  rewardController,
  shortVideoController,
  signController,
  skuController,
  smsController,
  softwarePackageController,
  stockCaseController,
  stockCaseNewController,
  stockInfoController,
  strategyApplyController,
  strategyBackTestController,
  strategyController,
  strategyCouponController,
  strategySimulateController,
  strategySimulationBackTestController,
  strategyStockController,
  streamController,
  surveyController,
  teamVideoController,
  userController,
  vipCouponController,
  vipNoticeController,
  vodController,
  webOssConfigController,
  weimobController,
  workWxController,
  wxController,
  wxMaController,
  wxOauth2Controller,
  wxTrackController,
};
